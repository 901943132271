<template>
  <layout inverse>
    <div class="home no-overflow-x" data-testid="homecontainer">
      <home-hero
        :hero-image="data?.homepage?.heroImage as AiPicture"
        :title1="data?.homepage?.title ?? ''"
        :title2="data?.homepage?.title2 ?? ''"
        :home-page-description="data?.homepage?.subtitle ?? ''"
        variant="inverse" />

      <home-collection-intro
        :collection-intro-title="data?.homepage?.introTitle ?? ''"
        :collection-intro-description="
          data?.homepage?.introDescription ?? ''
        " />

      <home-hotel-and-resorts id="hotel-and-resorts" :cards="featuredHotels" />

      <home-pillars-accordion
        v-if="data?.homepage?.sevenPillars?.length"
        id="pillars"
        :items="data?.homepage?.sevenPillars as PillarFragment[]" />

      <home-experience-categories-carousel
        id="experiences"
        :big-title="data?.homepage?.experienceCategoriesBigTitle ?? ''"
        :title="data?.homepage?.experienceCategoriesTitle ?? ''"
        :subtitle="data?.homepage?.experienceCategoriesSubtitle ?? ''"
        :experience-categories="
          data?.homepage?.experienceCategories as ExperienceCategory[]
        " />

      <home-iconic-destinations
        id="iconic-destinations"
        :title="data?.homepage?.iconicDestinationTitle ?? ''"
        :subtitle="data?.homepage?.iconicDestinationSubtitle ?? ''"
        :items="
          (data?.homepage?.destinations as DestinationFragment[]) || []
        " />

      <home-featured-testimonials
        v-if="data?.homepage?.featuredTestimonials?.length"
        id="testimonials"
        :testimonials="
          (data?.homepage?.featuredTestimonials || []) as TestimonialFragment[]
        " />

      <home-special-packages-carousel
        id="offers"
        :description="data?.homepage?.offerDescription ?? ''"
        :special-offers="
          data?.homepage?.specialPackages as OfferAndPackage[]
        " />

      <home-loyalty-banner />
    </div>
  </layout>
</template>

<script setup lang="ts">
import type { Thing, WithContext } from 'schema-dts';

import type {
  AiPicture,
  DestinationFragment,
  ExperienceCategory,
  Hotel,
  OfferAndPackage,
  PillarFragment,
  TestimonialFragment,
} from '~~/domains/graphql';
import { useHomepageAsyncData } from '~~/domains/graphql';
import { extractHotelStructuredData } from '~~/helpers';
import { pageView } from '~~/helpers/gtm.helper';

pageView({
  page_category: 'homepage',
  page_name: 'allinclusive::homepage',
  page_sub_category_level_1: 'allinclusive',
  page_sub_category_level_2: 'homepage',
});

const localePath = useLocalePath();
const config = useRuntimeConfig();

const { data, error } = await useHomepageAsyncData();

useAsyncDataError(error, 'homepage');

const seo = computed(() => data.value?.homepage?.seoMetadata);
useSeoMetadata({
  metadata: seo,
  fallback: { title: data?.value?.homepage?.title },
});

useJsonld(() => {
  const jsonld = [] as WithContext<Thing>[];
  const hotels = (data.value?.homepage?.featuredHotels ?? []) as Hotel[];

  hotels.forEach((hotel: Hotel) => {
    jsonld.push(
      extractHotelStructuredData(hotel, config.public.baseUrl, localePath),
    );
  });

  return jsonld;
});

const featuredHotels = computed(() =>
  (data.value?.homepage?.featuredHotels || []).map(hotel => {
    const item = {
      id: hotel?.id,
      slug: hotel?.slug,
      brand: hotel?.brand,
      city: hotel?.address?.city,
      country: hotel?.address?.country,
      label: hotel?.name,
      pictograms: [] as {
        label: string | undefined | null;
        url: string | undefined | null;
      }[],
      picture: hotel?.heroImage,
    };

    for (const experience of hotel?.experienceCategories || []) {
      item.pictograms.push({
        label: experience?.title,
        url: experience?.logo?.src,
      });
    }

    return item;
  }),
);
</script>

<style scoped lang="scss">
@use '@/assets/styles/utilities/constants';
@use '@/assets/styles/utilities/mixins';

.Home-PlaceholderButtonGroup {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: constants.$inner-05;
  margin: auto;
  padding: constants.$padding-07 constants.$padding-02;

  @include mixins.rem-fallback(max-width, 800);
}
</style>
